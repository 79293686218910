import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetUser.gql';
import UserDetail from './UserDetail';
import UserDistributionComplaint from './UserDistributionComplaint';
import UserEdit from './UserEdit';
import UserGiveAccess from './UserGiveAccess';
import UserGiveSubscription from './UserGiveSubscription';

export default function User() {
  const { path } = useRouteMatch();
  const { user } = useParams();

  const { error, loading, data } = useQuery(query, {
    variables: { user },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={data.getUser.label} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <UserDetail userData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/edit`} exact>
          <UserEdit userData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/access`} exact>
          <UserGiveAccess />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/subscription`} exact>
          <UserGiveSubscription userData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/complaint/:distributionId`} exact>
          <UserDistributionComplaint userData={data} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
