import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';
import toOptions from '~/utils/toOptions';

import ProductListResults from './ProductListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  search: '',
  productType: '',
  site: '',
  betType: '',
};

export default function ProductsList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const options = {
    productTypes: toOptions(Types.productTypes),
    sites: toOptions(Types.sites),
    betTypes: toOptions(Types.betTypes),
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.search}
            onChange={e => handleChange('search', e.target.value)}
          />

          <TextField
            className={classes.textField}
            label="Produkttyp"
            variant="outlined"
            value={state.productType}
            select
            onChange={e => {
              const { value } = e.target;

              // Reset betType when productType is not bettip/ticket
              if (!['bettip', 'ticket'].includes(value)) {
                handleChange('betType', '');
              }

              handleChange('productType', value);
            }}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.productTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.textField}
            label="Sajt"
            variant="outlined"
            value={state.site}
            select
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.sites.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          {['bettip', 'ticket'].includes(state.productType) && (
            <TextField
              className={classes.textField}
              label="Spelform"
              variant="outlined"
              value={state.betType}
              select
              onChange={e => handleChange('betType', e.target.value)}
            >
              <MenuItem value="">
                <em>Alla</em>
              </MenuItem>
              {options.betTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          )}
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>

        <Button
          component={Link}
          to={`${pathname}/add`}
          color="primary"
          variant="contained"
          startIcon={<Add />}
        >
          Lägg till
        </Button>
      </Box>
      <Box>
        <ProductListResults params={params} options={options} />
      </Box>
    </>
  );
}
