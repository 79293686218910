import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import formatPrice from '~/utils/formatPrice';

import query from './_GetAllIncome.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function IncomeListResults({ state }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      from: state.from || undefined,
      to: state.to || undefined,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getIncome;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Källa', 'Datum', 'Belopp'];

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Total inkomst för perioden: {formatPrice(res.totalAmount)}
          </Typography>
        </CardContent>
      </Card>
      <Box
        css={({ theme }) => ({
          marginTop: theme.spacing(3),
          marginLeft: 'auto',
          marginRight: 'auto',
        })}
      >
        <TableList
          page={page}
          pageSize={pageSize}
          data={res}
          headers={headers}
          updatePage={updatePage}
          updateRowSize={updateRowSize}
        >
          {res?.income.length > 0 ? (
            res.income.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>{row.source}</TableCell>
                <TableCell className={classes.tableCellDate}>
                  <Time date={new Date(row.date)} />
                </TableCell>
                <TableCell>{formatPrice(row.amount)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                Ingen inbetalning finns för denna filtrering. Prova att ange ett
                annat datumintervall.
              </TableCell>
            </TableRow>
          )}
        </TableList>
      </Box>
    </>
  );
}

IncomeListResults.propTypes = {
  state: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};
