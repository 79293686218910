import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Types } from 'trhub-utils';

import NoUserData from '~/components/NoUserData';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';
import formatPrice from '~/utils/formatPrice';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderTransactions({ order }) {
  const { transactions } = order;
  return (
    <TablePaper title="Transaktioner">
      {transactions.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Belopp</TableCell>
                <TableCell>Meddelande</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(
                transaction =>
                  transaction && (
                    <StyledTableRow key={transaction.id}>
                      <TableCell>
                        <Time date={new Date(transaction.date)} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          color="primary"
                          label={Types.transactionStatus[transaction.status]}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>{formatPrice(transaction.amount)}</TableCell>
                      <TableCell>{transaction.message}</TableCell>
                    </StyledTableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoUserData text="Den här ordern saknar transaktioner" />
      )}
    </TablePaper>
  );
}

OrderTransactions.propTypes = {
  order: orderPropType,
};
