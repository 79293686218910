import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { campaignPropType } from '~/propTypes';
import formatPrice from '~/utils/formatPrice';

export default function CampaignDetail({ campaignData }) {
  const { pathname } = useLocation();
  const {
    name,
    code,
    type,
    price,
    site,
    duration,
    expireDate,
    products,
    limit,
    endDate,
    endSubscriptionAfterDuration,
  } = campaignData.getCampaign;

  const tableRows = [
    ['Namn', name],
    ['Rabattkod', code],
    ['Rabattyp', Types.campaignTypes[type]],
    ['Sajt', Types.sites[site]],
  ];

  expireDate &&
    tableRows.push([
      'Sista datumet för att nyttja kampanjen',
      <Time key={code} date={new Date(expireDate)} />,
    ]);

  endDate &&
    tableRows.push([
      'Slutdatumet som kampanjen gäller för prenumerationen (endast för migrerings-kampanjer)',
      <Time key={code} date={new Date(endDate)} />,
    ]);

  duration &&
    tableRows.push([
      'Kampanjlängd',
      duration === 1 ? `${duration} månad` : `${duration} månader`,
    ]);

  products.some(product => product.type === 'subscription') &&
    tableRows.push([
      'Avsluta kopplad prenumeration efter att kampanjlängden har passerat',
      <FormControlLabel
        key={code}
        control={<Checkbox checked={endSubscriptionAfterDuration ?? false} />}
      />,
    ]);

  limit && tableRows.push(['Begränsat nyttjande', `${limit}`]);

  for (const [i, obj] of products.entries()) {
    tableRows.push(
      [
        `Produkt ${i + 1}`,
        `${obj.product.name} (ord.pris ${formatPrice(obj.product.price)})`,
      ],
      [`Inkomstfördelning för ${obj.product.name}`, `${obj.percentage} %`],
    );
  }

  tableRows.push([
    type === 'monthlyDiscount'
      ? 'Pris att betala per månad'
      : 'Pris att betala direkt',
    `${formatPrice(price)}`,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/edit`}
            variant="contained"
            color="secondary"
          >
            Redigera
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

CampaignDetail.propTypes = {
  campaignData: PropTypes.shape({
    getCampaign: campaignPropType,
  }).isRequired,
};
