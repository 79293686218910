import { useQuery } from '@apollo/client';
import { Box, Button, LinearProgress, MenuItem } from '@material-ui/core';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import DateInputField from '~/components/DateInputField';
import ErrorMessage from '~/components/ErrorMessage';
import { NumberField } from '~/components/NumberField';
import { productType } from '~/propTypes';
import query from '~/utils/_GetAllProducts.gql';
import toOptions from '~/utils/toOptions';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ett namn måste anges.'),
  type: Yup.string().required('En produkttyp måste anges.'),
  distributable: Yup.boolean(),
  price: Yup.number()
    .required('Pris måste anges.')
    .moreThan(-1, 'Pris måste vara 0 eller ett positivt heltal.'),
  // TODO: Change if Vat is dropdown-select
  vat: Yup.number()
    .moreThan(-1, 'Moms måste vara 0 eller ett positivt heltal.')
    .required('Moms måste anges.')
    .integer('Moms måste vara ett heltal.'),
  betType: Yup.array().when('type', {
    is: value => value === 'bettip' || value === 'ticket',
    then: Yup.array()
      .min(1, 'Minst en spelform måste anges.')
      .required('Minst en spelform måste anges.'),
  }),
  roundDate: Yup.date().when('type', {
    is: value => value === 'bettip',
    then: Yup.date('Ogiltigt datum.')
      .min(format(new Date(), 'yyyy-MM-dd'), 'Ogiltigt datum.')
      .nullable(),
  }),
  roundId: Yup.string().when('type', {
    is: value => value === 'bettip',
    then: Yup.string().required('Omgångs-ID måste anges.'),
  }),
  allowedPaymentMethods: Yup.array()
    .min(1, 'Minst en betalningsmetod måste anges.')
    .required('Minst en betalningsmetod måste anges.'),
  paymentInterval: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string()
      .required('Ett betalningsintervall måste anges.')
      .test(
        'notMonthlyNotification',
        'Månadsintervall får inte anges för aviseringar.',
        function (value) {
          if (
            value === '1' &&
            this.parent.allowedPaymentMethods.includes('notification')
          ) {
            return false;
          }
          return true;
        },
      ),
  }),
  site: Yup.string().required('En sajt måste anges.'),
  punches: Yup.number().when('type', {
    is: value => value === 'ticket',
    then: Yup.number()
      .moreThan(-1, 'Klippkort måste vara 0 eller ett positivt heltal.')
      .required('Klippkort måste anges.')
      .integer('Klippkort måste vara ett heltal.'),
  }),
  costCenter: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string().required('Ett kostnadsställe måste anges.'),
  }),
  project: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string().required('Ett projekt måste anges.'),
  }),
});

const skuValidation = Yup.object().shape({
  sku: Yup.string().required('SKU måste anges.'),
});

export default function ProductForm({
  initialValues,
  onSubmit,
  addProduct = false,
}) {
  const { error, data } = useQuery(query, {
    variables: {
      filterProductType: 'subscription',
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.getAllProducts || [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        addProduct
          ? // eslint-disable-next-line unicorn/prefer-spread
            validationSchema.concat(skuValidation)
          : validationSchema
      }
      validateOnBlur={false}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="name"
            label="Namn"
            variant="outlined"
          />
          <Field
            label="Produkttyp"
            component={TextField}
            select
            name="type"
            variant="outlined"
          >
            {toOptions(Types.productTypes).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {values.type === 'subscription' && (
            <>
              <Field
                component={CheckboxWithLabel}
                name="distributable"
                Label={{ label: 'Distribueringsbar (KRAV för Billecta)' }}
                type="checkbox"
                checked={values.distributable}
                variant="outlined"
              />
              <Field
                component={TextField}
                select
                name="paymentInterval"
                label="Betalningsinterval"
                variant="outlined"
              >
                {toOptions(Types.paymentIntervals).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={TextField}
                select
                name="products"
                label="Produkter"
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
              >
                {productList.map(product => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Field>
            </>
          )}
          {values.type === 'bettip' && (
            <>
              <DateInputField
                name="roundDate"
                label="Omgångsdatum"
                clearIconEnabled={values.roundDate !== ''}
                setFieldValue={setFieldValue}
                value={values.roundDate}
              />
              <Field
                component={TextField}
                name="roundId"
                label="Omgångs-ID"
                variant="outlined"
              />
            </>
          )}
          {addProduct && (
            <Field
              component={TextField}
              name="sku"
              label="SKU"
              variant="outlined"
            />
          )}
          <NumberField name="price" label="Pris" value={values.price} />
          <NumberField select name="vat" label="Moms" value={values.vat}>
            {Types.VATs.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </NumberField>
          <Field
            component={TextField}
            select
            name="site"
            label="Sajt"
            variant="outlined"
          >
            {toOptions(Types.sites).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            select
            name="allowedPaymentMethods"
            label="Betalningsalternativ"
            variant="outlined"
            SelectProps={{
              multiple: true,
            }}
          >
            {toOptions(Types.paymentMethods).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {(values.type === 'bettip' || values.type === 'ticket') && (
            <Field
              component={TextField}
              select
              name="betType"
              label="Spelformer"
              variant="outlined"
              SelectProps={{
                multiple: true,
              }}
            >
              {Types.betTypes.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          )}
          {values.type === 'ticket' && (
            <NumberField name="punches" label="Klipp" value={values.punches} />
          )}
          {values.type === 'subscription' && (
            <>
              <Field
                component={TextField}
                select
                name="costCenter"
                label="Kostnadsställe"
                variant="outlined"
              >
                {Types.allCostCenters.map(option => (
                  <MenuItem key={option} value={option.toString()}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={TextField}
                select
                name="project"
                label="Project"
                variant="outlined"
              >
                {Types.allProjects.map(option => (
                  <MenuItem key={option} value={option.toString()}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </>
          )}
          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

const productPropType = PropTypes.shape({
  ...productType,
  products: PropTypes.arrayOf(PropTypes.string),
});

ProductForm.propTypes = {
  initialValues: productPropType,
  onSubmit: PropTypes.func.isRequired,
  addProduct: PropTypes.bool,
};
