
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CampaignInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Campaign"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"duration"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"site"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"limit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expireDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endSubscriptionAfterDuration"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"productsLabel"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"products"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"percentage"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":243}};
    doc.loc.source = {"body":"fragment CampaignInfo on Campaign {\n  id\n  name\n  code\n  type\n  price\n  duration\n  site\n  limit\n  endDate\n  expireDate\n  endSubscriptionAfterDuration\n  productsLabel\n  products {\n    product {\n      name\n      price\n    }\n    percentage\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
