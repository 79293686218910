import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import OrderList from '~/components/OrderList';
import { userPropType } from '~/propTypes';

import Bettips from './Bettips';
import Distributions from './Distributions';
import Profile from './Profile';
import Subscriptions from './Subscriptions';
import Tickets from './Tickets';

export default function UserDetail({ userData }) {
  const { id } = userData.getUser;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Profile userData={userData} />
      </Grid>
      <Grid item xs={12}>
        <OrderList
          filter={{
            userId: id,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Subscriptions user={id} />
      </Grid>
      <Grid item xs={12}>
        <Distributions user={id} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Bettips user={id} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Tickets user={id} />
      </Grid>
    </Grid>
  );
}

UserDetail.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
