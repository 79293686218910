import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import Bugsnag from '~/utils/bugsnag';

const currentUser = gql`
  query CurrentUser {
    refreshUser {
      id
      name
      roles
    }
  }
`;

function AuthWrapper({ message, actions }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card css={{ minWidth: '300px' }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            TR Hub Admin
          </Typography>
          <Typography variant="h6" component="h2">
            {message}
          </Typography>
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </Box>
  );
}

AuthWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  actions: PropTypes.node,
};

function Auth({ children }) {
  const { data, loading, error } = useQuery(currentUser);

  useEffect(() => {
    const user = data?.refreshUser;
    if (user != null && user.roles.includes('admin')) {
      Bugsnag.setUser({ user: user.email });
    }
  }, [data]);

  if (error != null && error.networkError?.statusCode !== 401) {
    return <ErrorMessage error={error} />;
  }

  if (loading || !data) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data.refreshUser.roles.includes('admin')) {
    return (
      <AuthWrapper
        message="Du måste ha admin behörighet"
        actions={
          <Button
            component="a"
            href={`${
              process.env.REACT_APP_AUTH_URL
            }/logout?ReturnUrl=${encodeURI(window.location.href)}`}
            color="primary"
          >
            Logga ut
          </Button>
        }
      />
    );
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
