import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import PropTypes from 'prop-types';

const errorLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) {
    window.location.href = `${
      process.env.REACT_APP_AUTH_URL
    }?ReturnUrl=${encodeURI(window.location.href)}`;
  }
});

const httpLink = new HttpLink({
  uri: '/api',
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

Apollo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Apollo;
