import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { productPropType } from '~/propTypes';

import mutation from './_EditProduct.gql';
import ProductForm from './ProductForm';

export default function ProductEdit({ productData }) {
  const { product } = useParams();

  const [error, setError] = useState('');

  const [action] = useMutation(mutation);
  const history = useHistory();

  const {
    name,
    type,
    sku,
    price,
    vat,
    site,
    products,
    allowedPaymentMethods,
    paymentInterval,
    betType,
    roundId,
    roundDate,
    punches,
    distributable,
    costCenter,
    project,
  } = productData.getProduct;

  return (
    <>
      <Breadcrumb name="Redigera" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <ProductForm
        initialValues={{
          name,
          type,
          sku,
          price: Math.round(price / 100),
          vat,
          distributable: distributable || false,
          site,
          roundId: roundId || '',
          products: products.map(p => p.id),
          allowedPaymentMethods,
          roundDate: roundDate ? format(new Date(roundDate), 'yyyy-MM-dd') : '',
          paymentInterval: paymentInterval || '',
          betType,
          punches: punches || 0,
          costCenter: costCenter || '',
          project: project || '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const {
            paymentInterval,
            roundDate,
            price,
            vat,
            roundId,
            punches,
            costCenter,
            project,
            ...rest
          } = values;
          const modifiedValues = {
            ...rest,
            price: Number(price) * 100,
            vat: Number(vat),
            punches: Number(punches),
            paymentInterval: paymentInterval
              ? Number.parseInt(paymentInterval, 10)
              : null,
            roundDate: type === 'bettip' ? roundDate : null,
            roundId: type === 'bettip' ? Number.parseInt(roundId, 10) : null,
            costCenter: costCenter ? Number(costCenter) : null,
            project: project ? Number(project) : null,
          };

          const response = await action({
            variables: {
              id: product,
              input: modifiedValues,
            },
          }).catch(responseError => setError(responseError.message));
          setSubmitting(false);

          if (response) {
            history.push(`/products/${product}`);
          }
        }}
      />
    </>
  );
}

ProductEdit.propTypes = {
  productData: PropTypes.shape({
    getProduct: productPropType,
  }).isRequired,
};
