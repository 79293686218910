import { useQuery } from '@apollo/client';
import { DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import usePagination from '~/hooks/usePagination';
import useQueryParams from '~/hooks/useQueryParams';

export default function DataGrid({
  columns,
  query,
  filter,
  defaultSort,
  ...props
}) {
  const { page, pageSize, updatePage } = usePagination();

  const initialState = useMemo(() => ({ order: defaultSort }), [defaultSort]);

  const {
    state: { order },
    handleChange,
  } = useQueryParams(initialState);

  const sortModel = {
    field: order ? order.replace(/^-/, '') : undefined,
    sort: order ? (order.startsWith('-') ? 'desc' : 'asc') : undefined,
  };

  const { error, data, loading } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      sort: order
        ? {
            field: order.replace(/^-/, ''),
            sort: order.startsWith('-') ? 'desc' : 'asc',
          }
        : undefined,
      filter,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const res = data?.list;

  return (
    <div
      css={{
        overflowX: 'auto',
        // Add some scroll shadows
        backgroundImage: `linear-gradient(to right, white, white), 
          linear-gradient(to right, white, white), 
          linear-gradient(to right, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0)),
          linear-gradient(to left, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0))`,
        backgroundPosition:
          'left center, right center, left center, right center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
        backgroundAttachment: 'local, local, scroll, scroll',
      }}
    >
      <div css={{ minWidth: '800px' }}>
        <MuiDataGrid
          autoHeight
          columns={columns}
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          loading={loading}
          onPageChange={page => updatePage(page.page)}
          onSortModelChange={({ sortModel }) => {
            const [{ field, sort } = {}] = sortModel;
            handleChange(
              'order',
              field ? (sort === 'desc' ? '-' : '') + field : '',
            );
          }}
          page={page - 1}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={res?.total || 0}
          rows={res?.items || []}
          sortingMode="server"
          sortModel={order ? [sortModel] : undefined}
          {...props}
        />
      </div>
    </div>
  );
}

DataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  defaultSort: PropTypes.string.isRequired,
  filter: PropTypes.object,
  query: PropTypes.object.isRequired,
};
