import {
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import OrderList from '~/components/OrderList';
import TablePaper from '~/components/TablePaper';
import { subscriptionPropType } from '~/propTypes';

export default function SubscriptionDetail({ subscriptionData }) {
  const { pathname } = useLocation();

  const {
    id,
    campaign,
    user,
    payee,
    product,
    paymentMethod,
    startDate,
    dueDate,
    unsubscribeDate,
    endDate,
    pauseStartDate,
    pauseEndDate,
    isDistributable,
    latestRenewalAttempt,
    latestSuccessfulRenewal,
    drupalId,
  } = subscriptionData.getSubscription;

  const tableRows = [
    [
      'Prenumerant',
      <Link key={user.id} component={RouterLink} to={`/users/${user.id}`}>
        {user.label}
      </Link>,
    ],
    [
      'Produkt',
      <Link
        key={product.id}
        component={RouterLink}
        to={`/products/${product.id}`}
      >
        {product.name}
      </Link>,
    ],
    ['Betalningsmetod', Types.paymentMethods[paymentMethod]],
    ['Startdatum', format(new Date(startDate), 'yyyy-MM-dd')],
    ['Förfallodag', dueDate ? format(new Date(dueDate), 'yyyy-MM-dd') : ''],
    [
      'Uppsägningsdatum',
      unsubscribeDate ? format(new Date(unsubscribeDate), 'yyyy-MM-dd') : '',
    ],
    ['Slutdatum', endDate ? format(new Date(endDate), 'yyyy-MM-dd') : ''],
    [
      'Senaste förnyelse',
      latestSuccessfulRenewal
        ? format(new Date(latestSuccessfulRenewal), 'yyyy-MM-dd')
        : '',
    ],
    [
      'Senaste förnyelseförsök',
      latestRenewalAttempt
        ? format(new Date(latestRenewalAttempt), 'yyyy-MM-dd')
        : '',
    ],
    ['Kampanj', campaign ? campaign.name : ''],
  ];

  if (drupalId) {
    tableRows.push(['Drupal ID', drupalId]);
  }

  // insert distinct payee after user in the table
  user.id !== payee.id &&
    tableRows.splice(1, 0, [
      'Betalningsmottagare',
      <Link key={payee.id} component={RouterLink} to={`/users/${payee.id}`}>
        {payee.label}
      </Link>,
    ]);

  pauseStartDate &&
    tableRows.push([
      'Uppehåll från',
      format(new Date(pauseStartDate), 'yyyy-MM-dd'),
    ]);
  pauseEndDate &&
    tableRows.push([
      'Uppehåll till',
      format(new Date(pauseEndDate), 'yyyy-MM-dd'),
    ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>

        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/extend`}
            variant="contained"
            color="secondary"
          >
            Förläng förfallodag
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/cancel`}
            variant="contained"
            color="secondary"
          >
            Avsluta prenumeration
          </Button>
        </Box>
        {endDate && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/reactivate`}
              variant="contained"
              color="secondary"
            >
              Återuppta prenumeration
            </Button>
          </Box>
        )}
        {product.paymentInterval === 12 && isDistributable && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/pause`}
              variant="contained"
              color="secondary"
            >
              Pausa prenumeration
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderList filter={{ subscription: id }} />
      </Grid>
    </Grid>
  );
}

SubscriptionDetail.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
