import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import DateInputField from '~/components/DateInputField';
import { subscriptionPropType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

import mutation from './_CancelSubscription.gql';

const validationSchema = Yup.object().shape({
  unsubscribeReason: Yup.object().shape({
    reason: Yup.string().required(
      'En anledning till uppsägningen måste anges.',
    ),
  }),
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
}));

export default function SubscriptionCancel({ subscriptionData }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const { subscription } = useParams();
  const [error, setError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  const { endDate, dueDate, paymentMethod, product, unsubscribeReason } =
    subscriptionData.getSubscription;

  // Group by active property
  const unsubscribeReasons = toOptions(
    Object.entries(Types.getUnsubscribeReasons({ site: product.site })).reduce(
      (acc, [key, value]) => {
        acc[key] = `${value.text}${!value.active ? ' (Dold)' : ''}`;
        return acc;
      },
      {},
    ),
    false,
  );

  return (
    <>
      <Breadcrumb name="Avsluta prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Avsluta en prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Att avsluta en prenumeration innebär att kunden får tillgång till
            produkten fram till och med slutdatumet som sätts.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            En anledning varför prenumerationen sägs upp måste anges. Om inget
            slutdatum anges så sätts slutdatumet till nästkommande förfallodag.
          </Typography>
          {paymentMethod === 'autogiro' && (
            <Typography
              variant="body2"
              component="p"
              css={`
                margin-top: ${p => p.theme.spacing(2)}px;
              `}
            >
              Då denna prenumeration dras via autogiro så måste slutdatumet ske
              den 26:e i en kommande månad.
            </Typography>
          )}
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          endDate: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : '',
          unsubscribeReason: {
            reason: unsubscribeReason?.reason || '',
            text: unsubscribeReason?.text || '',
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (
            paymentMethod === 'autogiro' &&
            new Date(values.endDate).getDate() !== 26
          ) {
            setError('Slutdatum för autogiro kan endast sättas till den 26e.');
          } else {
            const response = await action({
              variables: {
                id: subscription,
                ...values,
              },
            });
            setSubmitting(false);

            if (response) {
              history.push(`/subscriptions/${subscription}`);
            }
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <DateInputField
              setFieldValue={setFieldValue}
              name="endDate"
              label="Slutdatum"
              value={values.endDate}
              minDate={dueDate ? new Date(dueDate) : new Date()}
            />
            <Field
              component={TextField}
              select
              name="unsubscribeReason.reason"
              label="Anledning till uppsägning"
              type="string"
              variant="outlined"
            >
              {unsubscribeReasons.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Field>
            {values.reason === 'other' && (
              <Field
                component={TextField}
                name="unsubscribeReason.text"
                label="Annat"
                variant="outlined"
              />
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </>
  );
}

SubscriptionCancel.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
