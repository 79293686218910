import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import DateInputField from '~/components/DateInputField';
import ErrorMessage from '~/components/ErrorMessage';
import { userPropType } from '~/propTypes';
import * as productsQuery from '~/utils/_GetAllProducts.gql';

import mutation from './_GiveSubscriptionToUser.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  productName: Yup.string().required('En produkt måste anges.'),
  endDate: Yup.date().required('Ett slutdatum måste anges.'),
});

export default function UserGiveSubscription({ userData }) {
  const classes = useStyles();

  const user = userData?.getUser;

  const [subscriptionActionError, setSubscriptionActionError] = useState('');
  const [missingAddressError, setMissingAddressError] = useState('');

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(productsQuery, {
    variables: {
      filterProductType: 'subscription',
    },
  });

  if (productsError) {
    return <Alert severity="error">{productsError.message}</Alert>;
  }

  if (subscriptionActionError) {
    return <ErrorMessage error={subscriptionActionError} />;
  }

  const productList = productsData?.getAllProducts ?? [];

  return (
    <>
      <Breadcrumb name="Ge gratisprenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Ge gratisprenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Ange en produkt och ett slutdatum för gratisprenumerationen.
            Prenumerationen kommer att börja gälla direkt.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Denna prenumeration kommer inte att bli kopplad till någon order.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          productName: '',
          endDate: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const product = productList.find(
            prod => prod.name === values.productName,
          );

          if (product.distributable === true && user.address?.address == null) {
            setMissingAddressError(
              'Eftersom detta är en pappersprenumeration måste kundens address vara ifylld!',
            );
          } else {
            const response = await action({
              variables: {
                user: user.id,
                ...values,
              },
            }).catch(responseError =>
              setSubscriptionActionError(responseError),
            );
            setSubmitting(false);

            if (response) {
              history.push(`/users/${user.id}`);
            }
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              noOptionsText={
                productsLoading ? 'Laddar in produkter...' : 'Inga träffar.'
              }
              name="productName"
              component={Autocomplete}
              filterSelectedOptions
              options={productList}
              getOptionLabel={option => option.name}
              filterOptions={createFilterOptions({
                limit: 150,
              })}
              onChange={(_, value) => setFieldValue('productName', value.name)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Sök produkt"
                  variant="outlined"
                  error={errors.productName != null}
                />
              )}
            />
            {errors.productName != null && (
              <Box mb={2}>
                <Alert severity="error">{errors.productName}</Alert>
              </Box>
            )}
            <DateInputField
              setFieldValue={setFieldValue}
              name="endDate"
              label="Slutdatum"
              clearIconEnabled={values.endDate !== ''}
              minDate={new Date()}
              required
            />
            {missingAddressError !== '' && (
              <Box mb={2}>
                <Alert severity="error">{missingAddressError}</Alert>
              </Box>
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserGiveSubscription.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
