import { Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';

import IncomeListResults from './IncomeListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function IncomeList({ state, handleChange }) {
  const classes = useStyles();

  const [error] = useState({ message: '' });

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }
  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
        </Box>
      </Box>
      <Box>
        <IncomeListResults state={state} />
      </Box>
    </>
  );
}

IncomeList.propTypes = {
  state: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
