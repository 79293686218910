import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';

import SubscriptionListResult from './SubscriptionListResult';

const initialValues = {
  active: '',
  paymentMethod: '',
  site: '',
  user: '',
};

export default function SubscriptionList() {
  const { pathname } = useLocation();

  const { params, state, handleChange, clear } = useQueryParams(initialValues);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          css={{
            '.MuiFormControl-root': {
              minWidth: '120px',
            },
          }}
        >
          <TextField
            label="Aktiva"
            variant="outlined"
            value={state.active}
            select
            onChange={e => handleChange('active', e.target.value)}
          >
            <MenuItem value="all">
              <em>Alla</em>
            </MenuItem>
            <MenuItem value="true">
              <em>Ja</em>
            </MenuItem>
            <MenuItem value="false">
              <em>Nej</em>
            </MenuItem>
          </TextField>
          <TextField
            label="Betalmetod"
            variant="outlined"
            value={state.paymentMethod}
            select
            onChange={e => handleChange('paymentMethod', e.target.value)}
          >
            <MenuItem value="all">
              <em>Alla</em>
            </MenuItem>
            {Object.entries(Types.paymentMethods).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Användare"
            variant="outlined"
            value={state.user}
            onChange={e => handleChange('user', e.target.value)}
          />
          <TextField
            label="Sajt"
            variant="outlined"
            value={state.site}
            select
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {Object.entries(Types.sites).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>

          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>

        <Button
          component={Link}
          to={`${pathname}/add`}
          color="primary"
          variant="contained"
          startIcon={<Add />}
        >
          Lägg till
        </Button>
      </Box>
      <Box>
        <SubscriptionListResult params={params} />
      </Box>
    </>
  );
}
